import { ViewportScroller } from '@angular/common';
import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'news',
        loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule),
    },
    // TODO /leaderboards is deprecated, use /rankings instead and delete
    {
        path: 'leaderboards',
        loadChildren: () => import('./pages/rankings/rankings.module').then(m => m.RankingsModule),
    },
    {
        path: 'rankings',
        loadChildren: () => import('./pages/rankings/rankings.module').then(m => m.RankingsModule),
    },
    {
        path: 'maps',
        loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsModule),
    },
    {
        path: 'achievements',
        loadChildren: () => import('./pages/achievements/achievements.module').then(m => m.AchievementsModule),
    },
    {
        path: 'nut',
        loadChildren: () => import('./pages/nut/nut.module').then(m => m.NutModule),
    },
    {
        path: 'HollowAssumption',
        loadChildren: () => import('./pages/hollow-assumption/hollow-assumption.module').then(m => m.HollowAssumptionModule),
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
    ],
    exports: [ RouterModule ],
})
export class AppRoutingModule { }
