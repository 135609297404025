import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { UserServiceInterface } from './user.service.interface';
import { UserInfo, UserRanking } from './user.types';

@Injectable({ providedIn: 'root' })
export class UserService implements UserServiceInterface {
    private cachedUserInfo: UserInfo;

    constructor(
        private http: HttpClient,
    ) { }

    getUserInfo(): Observable<UserInfo> {
        if (this.cachedUserInfo) {
            return of(this.cachedUserInfo);
        }

        return this.http.get<UserInfo>(`${environment.baseUrls.backend}/api/user/info`, {
            withCredentials: true,
        }).pipe(tap(userInfo => this.cachedUserInfo = userInfo));
    }

    getUserRanking(userId?: string): Observable<UserRanking> {
        let url = `${environment.baseUrls.backend}/api/user`;
        if (userId) {
            url += `/${userId}`;
        }
        url += '/achievements';

        return this.http.get<UserRanking>(url, {
            withCredentials: true,
        });
    }
}
