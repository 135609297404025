import { ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BackendInterceptorService } from './core/services/backend-interceptor.service';
import { CustomViewportScroller } from './core/services/custom-viewport-scroller.class';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,

        CoreModule,
        SharedModule,
    ],
    providers: [
        {
            provide: ViewportScroller,
            useClass: CustomViewportScroller,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BackendInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [ AppComponent ],
})
export class AppModule { }
