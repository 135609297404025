import { NgModule } from '@angular/core';

import { AchievementImagePipe } from './achievement-image.pipe';
import { IsCommunityLevelPipe } from './is-community-level.pipe';
import { MapCategoryIconPipe } from './map-category-icon.pipe';
import { MapDifficultyPipe } from './map-difficulty.pipe';
import { MetaAchievementCountPipe } from './meta-achievement-count.pipe';
import { NameConcatPipe } from './name-concat.pipe';
import { UserBorderPipe } from './user-border.pipe';
import { UserIconPipe } from './user-icon.pipe';
import { UserRefConcatPipe } from './user-ref-concat.pipe';

@NgModule({
    exports: [
        MapDifficultyPipe,
        AchievementImagePipe,
        MetaAchievementCountPipe,
        UserIconPipe,
        UserBorderPipe,
        MapCategoryIconPipe,
        IsCommunityLevelPipe,
        NameConcatPipe,
        UserRefConcatPipe,
    ],
    declarations: [
        MapDifficultyPipe,
        AchievementImagePipe,
        MetaAchievementCountPipe,
        UserIconPipe,
        UserBorderPipe,
        MapCategoryIconPipe,
        IsCommunityLevelPipe,
        NameConcatPipe,
        UserRefConcatPipe,
    ],
})
export class PipeModule {

}
