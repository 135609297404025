import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BehaviorSubject } from "rxjs";
import { PageConfig } from "./page.interface";

@Injectable({ providedIn: 'root' })
export class PageService {
    private static readonly PAGE_TITLE = 'Gemster\'s Workshop';
    private static readonly BREADCRUMB_DIVIDER = '-';

    latestPage$: BehaviorSubject<PageConfig> = new BehaviorSubject({
        breadcrumbs: [],
    });
    
    constructor(
        private titleService: Title,
    ) { }

    registerPage(config: PageConfig): void {
        let title = '';

        if (config.breadcrumbs.length) {
            title = config.breadcrumbs
                .map(bc => bc.title)
                .reverse()
                .join(` ${PageService.BREADCRUMB_DIVIDER} `);
            title += ` ${PageService.BREADCRUMB_DIVIDER} `;
        }
        
        this.titleService.setTitle(`${title}${PageService.PAGE_TITLE}`);

        this.latestPage$.next(config);
    }
}
