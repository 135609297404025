<mat-toolbar color="primary" class="mat-elevation-z12">
    <button mat-icon-button (click)="openSidenav()" *ngIf="isMobile" style="margin-right: 15px;"><mat-icon>menu</mat-icon></button>
    <strong>Gemster's Workshop</strong>
    <span class="toolbar-spacer"></span>
    <ng-container *ngIf="!userInfoLoading">
        <login-popup [userInfo]="userInfo"></login-popup>
    </ng-container>
</mat-toolbar>

<mat-sidenav-container class="mat-typography">
    <mat-sidenav opened class="mat-elevation-z6" [mode]="isMobile ? 'over' : 'side'" [fixedInViewport]="isMobile" [(opened)]="isOpened">
        <div class="menu-container">
            <div class="loading centered" *ngIf="menuLoading">
                <div>
                    <mat-spinner></mat-spinner>
                </div>
            </div>
            <ng-container *ngIf="!menuLoading">
                <ng-container *ngFor="let menuItem of menu">
                    <a class="link" [routerLink]="menuItem.link" routerLinkActive="active" #rla="routerLinkActive" (click)="closeSidenav()">
                        <mat-icon *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
                        <span>{{ menuItem.title }}</span>
                    </a>
                    <div *ngIf="menuItem.children" class="submenu" [class.open]="rla.isActive">
                        <a *ngFor="let subMenuItem of menuItem.children" class="small-link" [routerLink]="subMenuItem.link" routerLinkActive="active" (click)="closeSidenav()">
                            <mat-icon *ngIf="subMenuItem.icon">{{ subMenuItem.icon }}</mat-icon>
                            <span>{{ subMenuItem.title }}</span>
                        </a>
                    </div>
                </ng-container>
            </ng-container>

            <div class="spacer"></div>
            <a class="link discord-link" href="https://discord.gg/zsmgZXQ" target="_blank">
                <img src="/assets/img/discord-logo-white.png" class="discord-icon" />
                <span>Join our Discord!</span>
            </a>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="breadcrumbs">
            <span class="breadcrumb-divider-mobile" *ngIf="breadcrumbs.length > 0">&lt; </span>
            <a class="breadcrumb" [routerLink]="'/'">Home</a>
            <ng-container *ngFor="let bc of breadcrumbs; last as isLast">
                <span class="breadcrumb-divider"> / </span>
                <a
                    *ngIf="!isLast"
                    class="breadcrumb"
                    [routerLink]="bc.link"
                >{{ bc.title }}</a>
                <span class="breadcrumb last-item" *ngIf="isLast">{{ bc.title }}</span>
            </ng-container>
        </div>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
