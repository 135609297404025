import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class BackendInterceptorService implements HttpInterceptor {
    // tslint:disable-next-line:no-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map(event => {
                if (event instanceof HttpResponse && event.ok) {
                    if (typeof event.body === 'object') {
                        if (event.body.success) {
                            return event.clone({
                                body: event.body.result,
                            });
                        }
                    }
                }

                return event;
            }),
            catchError(event => {
                if (event instanceof HttpErrorResponse && event.error && Array.isArray(event.error.errors)) {
                    return throwError(event.error.errors);
                }

                return throwError(event);
            }),
        );
    }
}
