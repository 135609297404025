import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialCommonModule } from '../material-common.module';
import { PipeModule } from '../pipes/pipes.module';

import { AchievementComponent } from './achievement/achievement.component';
import { AppIconComponent } from './icon/app-icon.component';
import { LoginPopupComponent } from './login-popup/login-popup.component';
import { MapCodeComponent } from './map-code/map-code.component';
import { MapDifficultyComponent } from './map-overview/map-difficulty/map-difficulty.component';
import { MapHeroesComponent } from './map-overview/map-heroes/map-heroes.component';
import { MapOverviewComponent } from './map-overview/map-overview.component';
import { UserIconComponent } from './user-icon/user-icon.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialCommonModule,
        PipeModule,
    ],
    exports: [
        MapOverviewComponent,
        AppIconComponent,
        AchievementComponent,
        MapCodeComponent,
        LoginPopupComponent,
        UserIconComponent,
    ],
    declarations: [
        MapOverviewComponent,
        MapDifficultyComponent,
        MapHeroesComponent,

        AppIconComponent,
        AchievementComponent,
        MapCodeComponent,
        LoginPopupComponent,
        UserIconComponent,
    ],
})
export class ComponentsModule {

}
