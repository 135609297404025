import { Pipe, PipeTransform } from '@angular/core';
import { UserInfo } from 'src/app/core/services/user/user.types';

@Pipe({
    name: 'userBorder',
})
export class UserBorderPipe implements PipeTransform {
    private readonly BORDER_TYPES = [ 'bronze', 'silver', 'gold', 'platinum', 'diamond' ];
    private readonly BORDER_RANKS = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ];

    transform(userInfo: UserInfo): string {
        return `url("/assets/icons/borders/${this.getUserIcon(userInfo.points.officialTotal)}")`;
    }

    public getUserIcon(points: number): string {
        if (points < 100) {
            return 'about:blank';
        }

        const iconPoints = points - 100;
        const type = Math.floor(iconPoints / 1000);
        if (type >= this.BORDER_TYPES.length) {
            return `${this.BORDER_TYPES[this.BORDER_TYPES.length - 1]}-${this.BORDER_RANKS[this.BORDER_RANKS.length - 1]}.webp`;
        }

        const rank = Math.floor((iconPoints - (type * 1000)) / 100);

        return `${this.BORDER_TYPES[type]}-${this.BORDER_RANKS[rank]}.webp`;
    }

    private getRandomBorder(): string {
        return `${this.getRandom(this.BORDER_TYPES)}-${this.getRandom(this.BORDER_RANKS)}.webp`;
    }

    private getRandom(arr: string[]): string {
        const idx = Math.floor(Math.random() * arr.length);
        if (idx === arr.length) {
            return arr[idx - 1];
        }
        return arr[idx];
    }
}
