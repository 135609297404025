import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

import { MenuService } from './core/services/menu/menu.service';
import { Menu } from './core/services/menu/menu.types';
import { PageConfigBreadcrumb } from './core/services/page/page.interface';
import { PageService } from './core/services/page/page.service';
import { ResponsiveService } from './core/services/responsive/responsive.service';
import { UserService } from './core/services/user/user.service';
import { UserInfo } from './core/services/user/user.types';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnInit, OnDestroy {
    isMobile: boolean = true;

    subscriptions: Subscription = new Subscription();

    isOpened: boolean = true;

    userInfoLoading: boolean = true;
    userInfo: UserInfo;

    menuLoading: boolean = true;
    menu: Menu[];

    breadcrumbs: PageConfigBreadcrumb[] = [];

    @ViewChild(MatSidenav)
    sidenav: MatSidenav;

    constructor(
        private responsiveService: ResponsiveService,
        private userService: UserService,
        private menuService: MenuService,
        private pageService: PageService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(this.responsiveService.sizeChanged.subscribe(screenSize => {
            this.isMobile = screenSize === 's' || screenSize === 'm';
            this.isOpened = !this.isMobile;
        }));

        this.subscriptions.add(this.pageService.latestPage$.subscribe(latestPage => {
            this.breadcrumbs = latestPage.breadcrumbs;

            this.cdr.detectChanges();
        }));

        this.userService.getUserInfo().subscribe(userInfo => {
            this.userInfoLoading = false;
            this.userInfo = userInfo;
        }, error => {
            this.userInfoLoading = false;
            this.userInfo = undefined;
        });

        this.menuService.getMenu().subscribe(menu => {
            this.menuLoading = false;
            this.menu = menu;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openSidenav(): void {
        this.sidenav.open();
    }

    closeSidenav(): void {
        if (this.isMobile) {
            this.sidenav.close();
        }
    }
}
