<div *ngIf="userInfo">
    <div class="user-icon-container" (click)="openUserPopup()">
        <user-icon [userInfo]="userInfo"></user-icon>
        <div class="popup user-popup" [class.open]="userPopupOpen" #userPopup>
            <div class="popup-chevron"></div>
            <div class="popup-inner mat-elevation-z8">
                <user-icon [userInfo]="userInfo" size="big"></user-icon>
                <p class="userinfo">{{ userInfo.username }}#{{ userInfo.discriminator }}</p>
                <ng-container *ngIf="userInfo.points">
                    <p class="points"><app-icon icon="points-competitive" size="12"></app-icon> {{ userInfo.points.officialTotal }}</p>
                </ng-container>
                <a mat-flat-button color="primary" [href]="logoutUrl + returnUrl">
                    Logout
                </a>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!userInfo">
    <div class="login-link" (click)="openLoginPopup()">Login</div>
    <div class="popup login-popup" [class.open]="loginPopupOpen" #loginPopup>
        <div class="popup-chevron"></div>
        <div class="popup-inner mat-elevation-z8">
            <p>
                Login with Discord to see your progress with all the achievements!
            </p>
            <div>
                <a mat-flat-button color="primary" [href]="loginUrl + returnUrl">
                    <img _ngcontent-the-c44="" src="/assets/img/discord-logo-white.png" class="discord-icon">
                    Login
                </a>
            </div>
        </div>
    </div>
</div>
