import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserInfo } from 'src/app/core/services/user/user.types';

interface IconDimensions {
    borderSize: number;
    plainSize: number;
    icon: {
        width: number;
        height: number;
        top: number;
        left: number;
    };
}

@Component({
    selector: 'user-icon',
    templateUrl: './user-icon.component.html',
    styleUrls: [ './user-icon.component.scss' ],
})
export class UserIconComponent implements OnChanges {
    private static readonly SMALL_DIMENSIONS: IconDimensions = {
        borderSize: 76,
        plainSize: 35,
        icon: {
            width: 34,
            height: 34,
            top: 21,
            left: 22,
        },
    };
    private static readonly BIG_DIMENSIONS: IconDimensions = {
        borderSize: 150,
        plainSize: 90,
        icon: {
            width: 66,
            height: 66,
            top: 41,
            left: 44,
        },
    };

    @Input()
    userInfo: UserInfo;

    @Input()
    size: 'small' | 'big' = 'small';

    dimensions: IconDimensions = UserIconComponent.SMALL_DIMENSIONS;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.size) {
            this.updateSizes();
        }
    }

    private updateSizes(): void {
        if (this.size === 'small') {
            this.dimensions = UserIconComponent.SMALL_DIMENSIONS;
        } else if (this.size === 'big') {
            this.dimensions = UserIconComponent.BIG_DIMENSIONS;
        }
    }
}
