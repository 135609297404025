<div class="user-icon" [style.width.px]="dimensions.borderSize" [style.height.px]="dimensions.borderSize" *ngIf="userInfo.points && userInfo.points.officialTotal >= 100">
    <div class="icon-container" [style.top.px]="dimensions.icon.top" [style.left.px]="dimensions.icon.left">
        <svg class="icon" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" [attr.width]="dimensions.icon.width" [attr.height]="dimensions.icon.height" fill="url(#img1)">
            <defs>
                <pattern id="img1" patternUnits="userSpaceOnUse" width="32" height="32">
                    <image [attr.href]="userInfo | userIcon" x="0" y="0" width="32" height="32" />
                </pattern>
            </defs>
            <polygon points="15.5,0 31,9 31,26 15.5,32 0,26 0,9" />
        </svg>
    </div>

    <div class="border" [style.width.px]="dimensions.borderSize" [style.height.px]="dimensions.borderSize" [style.background-image]="userInfo | userBorder"></div>
</div>
<div class="user-icon-plain">
    <div class="user-icon-plain-inner centered" *ngIf="!userInfo.points || userInfo.points.officialTotal < 100" [style.width.px]="dimensions.borderSize" [style.height.px]="dimensions.borderSize">
        <img class="mat-elevation-z4" [src]="userInfo | userIcon"  [style.width.px]="dimensions.plainSize" [style.height.px]="dimensions.plainSize" />
    </div>
</div>