import { ViewportScroller } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from '@angular/common';

// Based upon BrowserViewportScroller impl https://github.com/angular/angular/blob/13.0.3/packages/common/src/viewport_scroller.ts#L64-L245
@Injectable()
export class CustomViewportScroller implements ViewportScroller {
    private offset: () => [number, number] = () => [0, 0];
    private window: Window;
    private scrollElement: HTMLElement;

    constructor(
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.window = window;
        this.scrollElement = <HTMLElement> this.document.getElementsByTagName('mat-sidenav-content').item(0);
    }

    /**
     * Configures the top offset used when scrolling to an anchor.
     * @param offset A position in screen coordinates (a tuple with x and y values)
     * or a function that returns the top offset position.
     *
     */
    setOffset(offset: [number, number] | (() => [number, number])): void {
        if (Array.isArray(offset)) {
            this.offset = () => offset;
        } else {
            this.offset = offset;
        }
    }

    /**
     * Retrieves the current scroll position.
     * @returns The position in screen coordinates.
     */
    getScrollPosition(): [number, number] {
        if (this.supportsScrolling()) {
            return [this.scrollElement.scrollTop, this.scrollElement.scrollLeft];
        } else {
            return [0, 0];
        }
    }

    /**
     * Sets the scroll position.
     * @param position The new position in screen coordinates.
     */
    scrollToPosition(position: [number, number]): void {
        if (this.supportsScrolling()) {
            this.scrollElement.scrollTo(position[0], position[1]);
        }
    }

    /**
     * Scrolls to an element and attempts to focus the element.
     *
     * Note that the function name here is misleading in that the target string may be an ID for a
     * non-anchor element.
     *
     * @param target The ID of an element or name of the anchor.
     *
     * @see https://html.spec.whatwg.org/#the-indicated-part-of-the-document
     * @see https://html.spec.whatwg.org/#scroll-to-fragid
     */
    scrollToAnchor(target: string): void {
        if (!this.supportsScrolling()) {
            return;
        }

        const elSelected = findAnchorFromDocument(this.document, target);

        if (elSelected) {
            this.scrollToElement(elSelected);
            // After scrolling to the element, the spec dictates that we follow the focus steps for the
            // target. Rather than following the robust steps, simply attempt focus.
            this.attemptFocus(elSelected);
        }
    }

    /**
     * Disables automatic scroll restoration provided by the browser.
     */
    setHistoryScrollRestoration(scrollRestoration: 'auto' | 'manual'): void {
        if (this.supportScrollRestoration()) {
            const history = this.window.history;
            if (history && history.scrollRestoration) {
                history.scrollRestoration = scrollRestoration;
            }
        }
    }

    /**
     * Scrolls to an element using the native offset and the specified offset set on this scroller.
     *
     * The offset can be used when we know that there is a floating header and scrolling naively to an
     * element (ex: `scrollIntoView`) leaves the element hidden behind the floating header.
     */
    private scrollToElement(el: HTMLElement): void {
        const rect = el.getBoundingClientRect();
        const left = rect.left + this.scrollElement.scrollTop;
        const top = rect.top + this.scrollElement.scrollLeft;
        const offset = this.offset();
        this.scrollElement.scrollTo(left - offset[0], top - offset[1]);
    }

    /**
     * Calls `focus` on the `focusTarget` and returns `true` if the element was focused successfully.
     *
     * If `false`, further steps may be necessary to determine a valid substitute to be focused
     * instead.
     *
     * @see https://html.spec.whatwg.org/#get-the-focusable-area
     * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLOrForeignElement/focus
     * @see https://html.spec.whatwg.org/#focusable-area
     */
    private attemptFocus(focusTarget: HTMLElement): boolean {
        focusTarget.focus();
        return this.document.activeElement === focusTarget;
    }

    /**
     * We only support scroll restoration when we can get a hold of window.
     * This means that we do not support this behavior when running in a web worker.
     *
     * Lifting this restriction right now would require more changes in the dom adapter.
     * Since webworkers aren't widely used, we will lift it once RouterScroller is
     * battle-tested.
     */
    private supportScrollRestoration(): boolean {
        try {
            if (!this.supportsScrolling()) {
                return false;
            }
            // The `scrollRestoration` property could be on the `history` instance or its prototype.
            const scrollRestorationDescriptor = getScrollRestorationProperty(this.window.history) ||
                getScrollRestorationProperty(Object.getPrototypeOf(this.window.history));
            // We can write to the `scrollRestoration` property if it is a writable data field or it has a
            // setter function.
            return !!scrollRestorationDescriptor &&
                !!(scrollRestorationDescriptor.writable || scrollRestorationDescriptor.set);
        } catch {
            return false;
        }
    }

    private supportsScrolling(): boolean {
        try {
            return !!this.window && !!this.window.scrollTo && 'pageXOffset' in this.window;
        } catch {
            return false;
        }
    }
}

function getScrollRestorationProperty(obj: any): PropertyDescriptor | undefined {
    return Object.getOwnPropertyDescriptor(obj, 'scrollRestoration');
}

function findAnchorFromDocument(document: Document, target: string): HTMLElement | null {
    const documentResult = document.getElementById(target) || document.getElementsByName(target)[0];

    if (documentResult) {
        return documentResult;
    }

    // `getElementById` and `getElementsByName` won't pierce through the shadow DOM so we
    // have to traverse the DOM manually and do the lookup through the shadow roots.
    if (typeof document.createTreeWalker === 'function' && document.body &&
        ((document.body as any).createShadowRoot || document.body.attachShadow)) {
        const treeWalker = document.createTreeWalker(document.body, NodeFilter.SHOW_ELEMENT);
        let currentNode = treeWalker.currentNode as HTMLElement | null;

        while (currentNode) {
            const shadowRoot = currentNode.shadowRoot;

            if (shadowRoot) {
                // Note that `ShadowRoot` doesn't support `getElementsByName`
                // so we have to fall back to `querySelector`.
                const result =
                    shadowRoot.getElementById(target) || shadowRoot.querySelector(`[name="${target}"]`);
                if (result) {
                    return result;
                }
            }

            currentNode = treeWalker.nextNode() as HTMLElement | null;
        }
    }

    return null;
}