import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export type AppIcon = 'points-community' | 'points-competitive' |
        'difficulty-0' | 'difficulty-1' | 'difficulty-1.5' | 'difficulty-2' | 'difficulty-2.5' | 'difficulty-3' | 'difficulty-3.5' |
        'difficulty-4' | 'difficulty-4.5' | 'difficulty-5' | 'difficulty-5.5' | 'difficulty-6' | 'difficulty-6.5' | 'difficulty-7' |
        'difficulty-7.5' | 'difficulty-8' | 'difficulty-rng' |
        'rank-league5' | 'rank-league4' | 'rank-league3' | 'rank-league2' | 'rank-league1' | 'rank-top500' |
        'rank-grandmaster' | 'rank-master' | 'rank-diamond' | 'rank-platinum' | 'rank-gold' | 'rank-silver' | 'rank-bronze';

@Component({
    selector: 'app-icon',
    template: `<img src="/assets/icons/{{url}}.{{ suffix }}" [style.width.px]="size" />`,
})
export class AppIconComponent implements OnChanges {
    @Input()
    icon: AppIcon;

    @Input()
    size: number = 16;

    url: string = '';
    suffix: string = 'png';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.icon && changes.icon.currentValue) {
            const parts = changes.icon.currentValue.split('-');

            this.suffix = parts[0] === 'difficulty' ? 'gif' : 'png';

            this.url = parts.join('/');
        }
    }
}
