import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { UserInfo } from 'src/app/core/services/user/user.types';

@Pipe({
    name: 'userIcon',
})
export class UserIconPipe implements PipeTransform {
    private readonly BASE_URL = 'https://cdn.discordapp.com/avatars';

    constructor(
        private sanitizer: DomSanitizer,
    ) { }

    transform(userInfo: UserInfo): string {
        let suffix = 'png';
        if (userInfo.avatar.indexOf('a_') === 0) {
            suffix = 'gif';
        }

        const url = `${this.BASE_URL}/${userInfo.id}/${userInfo.avatar}.${suffix}?size=128`;
        return url;
    }
}
