import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserInfo } from 'src/app/core/services/user/user.types';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'login-popup',
    templateUrl: './login-popup.component.html',
    styleUrls: [ './login-popup.component.scss' ],
})
export class LoginPopupComponent implements OnInit {
    readonly loginUrl: string = `${environment.baseUrls.backend}/redirect?returnUrl=`;
    readonly logoutUrl: string = `${environment.baseUrls.backend}/logout?returnUrl=`;
    returnUrl: string = decodeURIComponent(window.location.href);

    @Input()
    userInfo: UserInfo;

    @ViewChild('loginPopup')
    loginPopup: ElementRef;

    @ViewChild('userPopup')
    userPopup: ElementRef;

    loginPopupOpen: boolean = false;
    userPopupOpen: boolean = false;

    subscriptions: Subscription = new Subscription();

    constructor(
        private router: Router,
        private eventManager: EventManager,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        ).subscribe((event: NavigationEnd) => {
            this.returnUrl = decodeURIComponent(window.location.origin + event.url);
        }));
    }

    openLoginPopup(): void {
        if (this.loginPopupOpen) {
            return;
        }

        this.openPopup(newVal => this.loginPopupOpen = newVal, target => this.loginPopup.nativeElement.contains(target));
    }

    openUserPopup(): void {
        if (this.userPopupOpen) {
            return;
        }

        this.openPopup(newVal => this.userPopupOpen = newVal, target => this.userPopup.nativeElement.contains(target));
    }

    openPopup(toggleVar: (newVal: boolean) => void, contains: (target: EventTarget) => boolean): void {
        toggleVar(true);

        window.setTimeout(() => {
            const removeClickHandler = this.eventManager.addGlobalEventListener('window', 'click', (event: Event) => {
                if (contains(event.target)) {
                    return;
                }

                event.stopPropagation();
                event.preventDefault();

                toggleVar(false);
                removeClickHandler();
            });
        }, 1);
    }
}
