import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { MenuServiceInterface } from './menu.service.interface';
import { Menu } from './menu.types';

@Injectable({ providedIn: 'root' })
export class MenuService implements MenuServiceInterface {
    constructor(
        private http: HttpClient,
    ) { }

    public getMenu(): Observable<Menu[]> {
        return this.http.get<Menu[]>(`${environment.baseUrls.backend}/api/menu`, {
            withCredentials: true,
        });
    }
}
